import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto } from "api-shared";
import { apiDelete, apiPost } from "../../lib/api";
import { MethodQueryKeys } from "./query-keys";

type UseFavoriteMethodMutationOptions = {
    methodId: number;
    favorite: boolean;
};

export const useFavoriteMethodMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ methodId, favorite }: UseFavoriteMethodMutationOptions) => {
            const url = `methods/${methodId}/favorite`;
            return favorite ? apiPost<BasicMessageDto>(url) : apiDelete<BasicMessageDto>(url);
        },
        onSuccess: (_, { methodId }) => {
            queryClient.invalidateQueries(MethodQueryKeys.list());
            queryClient.invalidateQueries(MethodQueryKeys.getById(methodId));
        },
    });
};
