import { Paper } from "@mui/material";
import { MethodDetailDto, MethodMeasureTimerange, MethodViewType } from "api-shared";
import { Route, Routes } from "react-router-dom";
import MethodDetailBanner from "./MethodDetailBanner";
import MethodOverviewBanner from "./MethodOverviewBanner";

interface MethodBannerProps {
    previousMethod: MethodDetailDto | undefined;
    nextMethod: MethodDetailDto | undefined;
    timerange: MethodMeasureTimerange;
    onTimerangeChange: (newTimerange: MethodMeasureTimerange) => void;
    view: MethodViewType;
    onViewChange: (newView: MethodViewType) => void;
    searchKey: string;
    onSearchKeyChange: (newSearchKey: string) => void;
}

const MethodBanner = ({
    previousMethod,
    nextMethod,
    timerange,
    view,
    searchKey,
    onViewChange,
    onTimerangeChange,
    onSearchKeyChange,
}: MethodBannerProps) => {
    return (
        <Paper square sx={{ px: { xs: 3, lg: 10 }, py: 1.625 }}>
            <Routes>
                <Route path=":code" element={<MethodDetailBanner previousMethod={previousMethod} nextMethod={nextMethod} />} />
                <Route
                    path="*"
                    element={
                        <MethodOverviewBanner
                            view={view}
                            onViewChange={onViewChange}
                            timerange={timerange}
                            onTimerangeChange={onTimerangeChange}
                            updateSearchKey={onSearchKeyChange}
                            searchKey={searchKey}
                        />
                    }
                />
            </Routes>
        </Paper>
    );
};

export default MethodBanner;
