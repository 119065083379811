import { MethodDetailDto, MethodTableColumn, nonNullable } from "api-shared";
import { FooterProps } from "react-table";
import TableCurrencyCell from "../../components/table/TableCurrencyCell";

const TableFooterAverageEffectCell = ({ rows }: FooterProps<MethodDetailDto>) => {
    const effect = rows
        .map((row) => row.values[MethodTableColumn.EFFECT] as number | undefined)
        .filter(nonNullable)
        .reduce((a, b) => a + b, 0);
    const count = rows
        .map((row) => row.values[MethodTableColumn.NUMBER_OF_MEASURES] as number | undefined)
        .filter(nonNullable)
        .reduce((a, b) => a + b, 0);

    return <TableCurrencyCell value={count > 0 ? effect / count : null} />;
};

export default TableFooterAverageEffectCell;
