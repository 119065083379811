import { styled } from "@mui/material";

const StyledRelevancySquare = styled("span", { shouldForwardProp: (prop) => prop !== "isDisabled" })<{ isDisabled?: boolean }>(
    ({ theme, isDisabled }) => ({
        display: "inline-block",
        height: theme.typography.fontSize,
        width: theme.typography.fontSize,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(0.25),
        "&:last-child": {
            marginRight: 0,
        },
        ...(isDisabled && {
            backgroundColor: theme.palette.action.disabledBackground,
        }),
    }),
);

export interface RelevancySquareProps {
    disabled?: boolean;
}

function RelevancySquare({ disabled }: Readonly<RelevancySquareProps>) {
    return <StyledRelevancySquare isDisabled={disabled} />;
}

export default RelevancySquare;
