import { isNumber } from "lodash";
import React from "react";
import { HeaderProps } from "react-table";

const TableFooterSumCell = <D extends object = Record<string, unknown>>({ column: { id, Cell }, rows, ...cellProps }: HeaderProps<D>) => {
    const sum = rows
        .map((row) => row.values[id])
        .filter(isNumber)
        .reduce((a, b) => a + b, 0);
    const Component = Cell as React.ComponentType<any>;
    // Cell can be string|number|ReactElement|... so it cannot be directly used as react component
    // Be careful, the cell renderer should not try to access row prop, it will not be available
    return <Component value={sum} rows={rows} {...cellProps} />;
};

export default TableFooterSumCell;
