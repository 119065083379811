import { MethodDetailDto, MethodViewType, Sort } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { Language } from "../../translations/main-translations";
import MethodTable from "./MethodTable";
import MethodTileView from "./MethodTileView";

interface MethodsOverviewProps {
    methods: MethodDetailDto[];
    view: MethodViewType;
    orderBy: string;
    sort: Sort;
    updateSorting: (orderBy: string, sort: Sort) => void;
    updateFavorite: (methodId: number, newStatus: boolean) => void;
    currentLanguage: Language;
    translate: TFunction;
    updateOrder: (newOrder: number[]) => void;
    clientName: string;
    isFetching: boolean;
}

const MethodsOverview = ({
    view,
    updateFavorite,
    updateSorting,
    methods,
    sort,
    orderBy,
    currentLanguage,
    translate,
    updateOrder,
    clientName,
    isFetching,
}: MethodsOverviewProps) => {
    return view === MethodViewType.LIST ? (
        <MethodTable
            translate={translate}
            language={currentLanguage}
            methods={methods}
            orderBy={orderBy}
            sort={sort}
            updateSorting={updateSorting}
            updateOrder={updateOrder}
            clientName={clientName}
            isFetching={isFetching}
        />
    ) : (
        <MethodTileView
            isFetching={isFetching}
            methods={methods}
            language={currentLanguage}
            translate={translate}
            updateFavorite={updateFavorite}
        />
    );
};
export default React.memo(MethodsOverview);
