import { MethodDetailDto } from "api-shared";
import { CellProps } from "react-table";
import TableLinkCell from "../../components/table/TableLinkCell";
import { RouteFor } from "../../lib/routes";

const MethodNameCell = ({ row, column, ...props }: CellProps<MethodDetailDto>) => (
    <TableLinkCell link={RouteFor.method.forCode(row.original.code)} row={row} column={column} {...props} />
);

export default MethodNameCell;
