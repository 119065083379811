import { Grid } from "@mui/material";
import { MethodDetailDto } from "api-shared";
import { TFunction } from "i18next";
import { useValueLevers } from "../../domain/endpoint";
import useCurrency from "../../hooks/useCurrency";
import { compareValueLever } from "../../lib/sort";
import { Language } from "../../translations/main-translations";
import MethodTileCategory from "./MethodTileCategory";

interface MethodTileViewProps {
    methods: MethodDetailDto[];
    language: Language;
    translate: TFunction;
    updateFavorite: (methodId: number, newStatus: boolean) => void;
    isFetching: boolean;
}

const MethodTileView = ({ methods, language, translate, updateFavorite, isFetching }: MethodTileViewProps) => {
    const { formatCurrency } = useCurrency();
    const valueLevers = useValueLevers();

    if (isFetching || valueLevers.length === 0) {
        return (
            <MethodTileCategory
                isFetching
                methods={methods}
                translate={translate}
                language={language}
                updateFavorite={updateFavorite}
                formatCurrency={formatCurrency}
            />
        );
    }

    return (
        <Grid container spacing={4}>
            {[...valueLevers].sort(compareValueLever).map((valueLever) => {
                const methodsForValueLever = methods.filter((m) => m.valueLeverId === valueLever.id);
                return methodsForValueLever.length > 0 ? (
                    <Grid key={valueLever.id} item xs={12}>
                        <MethodTileCategory
                            isFetching={isFetching}
                            valueLever={valueLever}
                            methods={methodsForValueLever}
                            translate={translate}
                            language={language}
                            updateFavorite={updateFavorite}
                            formatCurrency={formatCurrency}
                        />
                    </Grid>
                ) : null;
            })}
        </Grid>
    );
};

export default MethodTileView;
