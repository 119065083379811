import { Grid, Paper, Skeleton, SkeletonProps, Typography } from "@mui/material";
import { MethodDetailDto, ValueLeverDto } from "api-shared";
import { TFunction } from "i18next";
import { times } from "lodash";
import ValueLeverIcon from "../../components/icons/method-types/ValueLeverIcon";
import { useClientName } from "../../domain/client";
import { CurrencyFormatter } from "../../hooks/useCurrency";
import { compareMethodByCode } from "../../lib/sort";
import { Language } from "../../translations/main-translations";
import MethodTile from "./MethodTile";

interface MethodTileCategoryProps {
    valueLever?: ValueLeverDto;
    language: Language;
    methods: MethodDetailDto[];
    translate: TFunction;
    updateFavorite: (methodId: number, newStatus: boolean) => void;
    formatCurrency: CurrencyFormatter;
    isFetching: boolean;
}

const responsiveTileSizes = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
    xl: 2,
};

const MethodTileSkeleton = (props: SkeletonProps) => {
    return (
        <Paper elevation={0} sx={{ overflow: "hidden" }}>
            <Skeleton width="100%" height={300} variant="rectangular" {...props} />
        </Paper>
    );
};

const MethodTileCategory = ({
    valueLever,
    methods,
    language,
    translate,
    updateFavorite,
    formatCurrency,
    isFetching,
}: MethodTileCategoryProps) => {
    const hasValueLever = !isFetching && valueLever != null;
    const isCustomValueLever = valueLever?.code === "XX";
    const clientName = useClientName();
    const methodAlias = isCustomValueLever ? (
        `${clientName}`
    ) : hasValueLever ? (
        `${translate(valueLever.alias, { code: valueLever.code })}`
    ) : (
        <Skeleton width={100} />
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
                {hasValueLever ? (
                    <ValueLeverIcon sx={{ mr: 2 }} type={valueLever.alias} />
                ) : (
                    <Skeleton variant="circular" width="1.5rem" height="1.5rem" sx={{ mr: 2 }} />
                )}
                <Typography variant="h5">
                    {methodAlias}
                    <Typography color="textSecondary">
                        {hasValueLever ? translate(`${valueLever.alias}Hint`) : <Skeleton width={100} />}
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="stretch">
                {isFetching
                    ? times(6, (i) => (
                          <Grid item {...responsiveTileSizes}>
                              <MethodTileSkeleton />
                          </Grid>
                      ))
                    : [...methods].sort(compareMethodByCode).map((m) => (
                          <Grid item key={m.id} {...responsiveTileSizes}>
                              <MethodTile
                                  method={m}
                                  language={language}
                                  translate={translate}
                                  updateFavorite={updateFavorite}
                                  formatCurrency={formatCurrency}
                              />
                          </Grid>
                      ))}
            </Grid>
        </Grid>
    );
};

export default MethodTileCategory;
