import { styled } from "@mui/material";
import { CellProps } from "react-table";
import FavoriteIcon from "../../components/icons/FavoriteIcon";

const CenteredContent = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    // 24px icon would enlarge table row, allow to expand a little into the padding of parent table cell element
    margin: theme.spacing(-0.5, 0),
}));

function FavoriteCell<D extends object = Record<string, unknown>>({ value }: Readonly<Pick<CellProps<D>, "value" | "cell">>) {
    return (
        <CenteredContent>
            <FavoriteIcon value={value} />
        </CenteredContent>
    );
}

export default FavoriteCell;
