import { styled } from "@mui/material";
import { MethodDetailDto, MethodMeasureTimerange, MethodTableColumn, MethodViewType, Sort } from "api-shared";
import { useCallback, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { useTranslation } from "react-i18next";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import BannerLayout from "../../components/BannerLayout";
import { useClientName } from "../../domain/client";
import { useFavoriteMethodMutation } from "../../domain/methods/favorite";
import { useMethodsQuery } from "../../domain/methods/methods";
import { useUiState } from "../../domain/ui-state";
import { useDebounce } from "../../hooks/useDebounce";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useLanguage } from "../../hooks/useLanguage";
import { RouteFor } from "../../lib/routes";
import { KEY_MAP, SHORTCUTS } from "../../lib/shortcuts";
import { compareMethodByCode } from "../../lib/sort";
import { translationKeys } from "../../translations/main-translations";
import MethodDetailView from "../method/MethodDetailView";
import MethodBanner from "./MethodBanner";
import MethodsOverview from "./MethodsOverview";

const getDefaultMethodOrder = (methods: MethodDetailDto[]) =>
    methods != null ? [...methods].sort(compareMethodByCode).map((m) => m.id) : [];

const getPreviousMethod = (methods: MethodDetailDto[], method: MethodDetailDto, order?: number[]) => {
    const currentOrder = order ?? getDefaultMethodOrder(methods);
    const currentIndex = method != null ? currentOrder.findIndex((id) => id === method.id) : 0;
    const prevIndex = (currentIndex + currentOrder.length - 1) % currentOrder.length;
    return methods.find((m) => m.id === currentOrder[prevIndex]);
};

const getNextMethod = (methods: MethodDetailDto[], method: MethodDetailDto, order?: number[]) => {
    const currentOrder = order ?? getDefaultMethodOrder(methods);
    const currentIndex = method != null ? currentOrder.findIndex((id) => id === method.id) : 0;
    const nextIndex = (currentIndex + 1) % currentOrder.length;
    return methods.find((m) => m.id === currentOrder[nextIndex]);
};

const Content = styled("div")(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(2, 10),
    },
}));

const MethodsView = () => {
    const navigate = useNavigate();

    const [uiState, updateUiState] = useUiState();

    const { t: translate } = useTranslation();
    const currentLanguage = useLanguage();
    const clientName = useClientName();

    const view = uiState.methodViewType;
    const timerange = uiState.methodMeasureTimerange;

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey, 500);

    const methodsQuery = useMethodsQuery({ searchKey: debouncedSearchKey, timerange });
    const favoriteMethodMutation = useFavoriteMethodMutation().mutate;

    const updateSorting = useCallback(
        (orderBy: string, sort: Sort) => {
            if (orderBy !== uiState.methodTableOrderBy || sort !== uiState.methodTableSort) {
                updateUiState({
                    methodTableOrderBy: orderBy,
                    methodTableSort: sort,
                });
            }
        },
        [uiState.methodTableOrderBy, uiState.methodTableSort, updateUiState],
    );

    const updateViewType = (methodViewType: MethodViewType) => updateUiState({ methodViewType });
    const updateTimerange = (methodMeasureTimerange: MethodMeasureTimerange) => updateUiState({ methodMeasureTimerange });
    const updateMethodFavorite = useCallback(
        (methodId: number, favorite: boolean) => favoriteMethodMutation({ methodId, favorite }),
        [favoriteMethodMutation],
    );
    const updateSearchKey = useCallback(
        (newSearchKey: string) => {
            setSearchKey(newSearchKey);

            if (newSearchKey !== "") {
                updateSorting(MethodTableColumn.RELEVANCY, Sort.DESCENDING);
            }
        },
        [updateSorting],
    );

    const [order, setOrder] = useState<number[]>();

    useDocumentTitle(translationKeys.VDLANG_METHODS_NAVBAR_ITEM_LABEL);

    // :code is only available in route context as the parent route context is only "/methods"
    const match = useMatch(RouteFor.method.withCodeParam);
    const code = match?.params.code ?? undefined;

    const methods = methodsQuery.data;
    const method = methods?.find((m) => m.code === code);
    const nextMethod = methods !== undefined && method !== undefined ? getNextMethod(methods, method, order) : undefined;
    const previousMethod = methods !== undefined && method !== undefined ? getPreviousMethod(methods, method, order) : undefined;

    const gotoNextMethod = useCallback(() => {
        nextMethod !== undefined && navigate(RouteFor.method.forCode(nextMethod.code));
    }, [nextMethod, navigate]);

    const gotoPreviousMethod = useCallback(() => {
        previousMethod !== undefined && navigate(RouteFor.method.forCode(previousMethod.code));
    }, [previousMethod, navigate]);

    return (
        <BannerLayout
            banner={
                <MethodBanner
                    previousMethod={previousMethod}
                    nextMethod={nextMethod}
                    timerange={timerange}
                    view={view}
                    searchKey={searchKey}
                    onViewChange={updateViewType}
                    onTimerangeChange={updateTimerange}
                    onSearchKeyChange={updateSearchKey}
                />
            }
        >
            <Content>
                <GlobalHotKeys
                    allowChanges
                    handlers={{
                        [SHORTCUTS.NAVIGATE_LEFT]: gotoPreviousMethod,
                        [SHORTCUTS.NAVIGATE_RIGHT]: gotoNextMethod,
                    }}
                    keyMap={KEY_MAP}
                />
                <Routes>
                    <Route path=":code" element={<MethodDetailView updateFavorite={updateMethodFavorite} translate={translate} />} />
                    <Route
                        path="*"
                        element={
                            <MethodsOverview
                                methods={methodsQuery.data ?? []}
                                view={view}
                                orderBy={uiState.methodTableOrderBy}
                                sort={uiState.methodTableSort}
                                updateSorting={updateSorting}
                                updateFavorite={updateMethodFavorite}
                                currentLanguage={currentLanguage}
                                translate={translate}
                                updateOrder={setOrder}
                                clientName={clientName}
                                isFetching={!methodsQuery.isSuccess}
                            />
                        }
                    />
                </Routes>
            </Content>
        </BannerLayout>
    );
};

export default MethodsView;
