import { MethodDetailDto, MethodTableColumn, Sort } from "api-shared";
import { TFunction } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { Column, Row, SortingRule } from "react-table";
import PaperTable from "../../components/table/PaperTable";
import TableCurrencyCell from "../../components/table/TableCurrencyCell";
import TableFooterSumCell from "../../components/table/TableFooterSumCell";
import TableHeaderCell from "../../components/table/TableHeaderCell";
import TableRelevancyCell from "../../components/table/TableRelevancyCell";
import TableTextCell from "../../components/table/TableTextCell";
import { compareMethodByCode } from "../../lib/sort";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";
import FavoriteCell from "./FavoriteCell";
import MethodNameCell from "./MethodNameCell";
import TableFooterAverageEffectCell from "./TableFooterAverageEffectCell";

const sortMethodsRows = (a: Row<MethodDetailDto>, b: Row<MethodDetailDto>) => compareMethodByCode(a.original, b.original);
const sortFavorites = (a: Row<MethodDetailDto>, b: Row<MethodDetailDto>) =>
    (a.original.isFavorite ? 1 : 0) - (b.original.isFavorite ? 1 : 0);

interface MethodTableProps {
    methods: MethodDetailDto[];
    translate: TFunction;
    language: Language;
    orderBy: string;
    sort: Sort;
    updateSorting: (orderBy: string, sort: Sort) => void;
    updateOrder: (newOrder: number[]) => void;
    clientName: string;
    isFetching: boolean;
}

const MethodTable = ({
    methods,
    translate,
    language,
    orderBy,
    sort,
    updateSorting,
    updateOrder,
    clientName,
    ...tableProps
}: MethodTableProps) => {
    const tableSort = useMemo(() => [{ id: orderBy, desc: sort === "DESC" }], [orderBy, sort]);

    const onSortedChange = useCallback(
        (newSorted: SortingRule<MethodDetailDto>[]) => {
            // keep current sorting when sort is removed completely
            if (newSorted.length > 0) {
                const { id: newOrderBy, desc } = newSorted[0];
                const newSort = desc ? Sort.DESCENDING : Sort.ASCENDING;
                if (newOrderBy !== orderBy || newSort !== sort) {
                    updateSorting(newOrderBy, newSort);
                }
            }
        },
        [orderBy, sort, updateSorting],
    );

    /*
     * Check the sorted table data and call updateOrder(...) if the order has changed
     * This is dirty, but there is no better way with react-table
     */
    const [order, setOrder] = useState<number[]>([]);

    const compareAndUpdateOrder = useCallback(
        (updatedOrder: MethodDetailDto[]) => {
            const newOrder = updatedOrder.map(({ id }) => id);
            if (updatedOrder.length !== order.length || !newOrder.every((v, i) => order[i] === v)) {
                setOrder(newOrder);
                typeof updateOrder === "function" && updateOrder(newOrder);
            }
        },
        [order, updateOrder],
    );

    const columns = useMemo<Column<MethodDetailDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                accessor: "code",
                sortType: sortMethodsRows,
                id: MethodTableColumn.CODE,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_CODE_COLUMN_LABEL),
                width: 100,
                Cell: TableTextCell,
            },
            {
                Header: TableHeaderCell,
                accessor: (method) => translateFromProperty(method, "name", language),
                id: MethodTableColumn.NAME,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_NAME_COLUMN_LABEL),
                Cell: MethodNameCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "isFavorite",
                sortType: sortFavorites,
                id: MethodTableColumn.FAVORITE,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_FAVORITE_COLUMN_LABEL),
                headerTooltip: translate(translationKeys.VDLANG_METHOD_TABLE_FAVORITE_COLUMN_HINT),
                width: 100,
                Cell: FavoriteCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "totalNumberOfMeasures",
                id: MethodTableColumn.NUMBER_OF_MEASURES,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_COUNT_COLUMN_LABEL),
                headerTooltip: translate(translationKeys.VDLANG_METHOD_TABLE_COUNT_COLUMN_HINT, { clientName }),
                width: 75,
                Cell: (props) => <TableTextCell align="right" {...props} />,
                Footer: TableFooterSumCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "totalEffect",
                id: MethodTableColumn.EFFECT,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_EFFECT_COLUMN_LABEL),
                headerTooltip: translate(translationKeys.VDLANG_METHOD_TABLE_EFFECT_COLUMN_HINT, { clientName }),
                width: 200,
                Cell: TableCurrencyCell,
                Footer: TableFooterSumCell,
            },
            {
                Header: TableHeaderCell,
                accessor: (r) => (r.totalNumberOfMeasures ? (r.totalEffect ?? 0) / r.totalNumberOfMeasures : 0),
                id: MethodTableColumn.AVERAGE_EFFECT,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_LABEL),
                headerTooltip: translate(translationKeys.VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_HINT, { clientName }),
                width: 200,
                Cell: TableCurrencyCell,
                Footer: TableFooterAverageEffectCell,
            },
            {
                Header: TableHeaderCell,
                accessor: "relevancy",
                id: MethodTableColumn.RELEVANCY,
                label: translate(translationKeys.VDLANG_METHOD_TABLE_RELEVANCY),
                headerTooltip: <Trans<translationKeys> i18nKey={translationKeys.VDLANG_METHOD_TABLE_RELEVANCY_HINT} />,
                width: 150,
                Cell: TableRelevancyCell,
            },
        ],
        [language, translate, clientName],
    );

    return (
        <PaperTable
            fullHeight
            data={methods}
            columns={columns}
            disablePagination
            disableSortRemove
            defaultSortBy={tableSort}
            onSortByChanged={onSortedChange}
            onOrderChanged={compareAndUpdateOrder}
            noDataText={translate(translationKeys.VDLANG_METHOD_TABLE_NO_METHODS)}
            translate={translate}
            {...tableProps}
        />
    );
};

export default MethodTable;
