import ViewListIcon from "@mui/icons-material/ViewListRounded";
import ViewModuleIcon from "@mui/icons-material/ViewModuleRounded";
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FeedbackType, MethodMeasureTimerange, MethodViewType } from "api-shared";
import { useTranslation } from "react-i18next";
import FeedbackDialog from "../../components/dialogues/FeedbackDialog";
import SearchInput from "../../components/input/SearchInput";
import Select from "../../components/input/select/Select";
import useDialog from "../../hooks/useDialog";
import { translationKeys } from "../../translations/main-translations";

const MethodViewTypeButtons = {
    [MethodViewType.LIST]: ViewListIcon,
    [MethodViewType.TILE]: ViewModuleIcon,
};
interface MethodOverviewBannerProps {
    timerange: MethodMeasureTimerange;
    onTimerangeChange: (newTimerange: MethodMeasureTimerange) => void;
    view: MethodViewType;
    onViewChange: (newView: MethodViewType) => void;
    searchKey: string;
    updateSearchKey: (newSearchKey: string) => void;
}

const MethodOverviewBanner = ({
    view,
    onViewChange,
    timerange,
    onTimerangeChange,
    updateSearchKey,
    searchKey,
}: MethodOverviewBannerProps) => {
    const { t: translate } = useTranslation();

    const feedbackDialog = useDialog();

    const onSearchKeyChange = (searchInput: string) => {
        if (searchInput !== searchKey) {
            updateSearchKey(searchInput);
        }
    };

    const updateView = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        // when clicking on the currently selected button, onChange is triggered with value null
        if (value != null) {
            onViewChange(+value);
        }
    };

    const timerangeOptions = Object.values(MethodMeasureTimerange)
        .filter((k): k is MethodMeasureTimerange => typeof k === "number")
        .map((value) => ({ value, label: translate(`MethodMeasureTimerange.${value}`) }));
    const selectedTimerangeOption = timerangeOptions.find(({ value }) => value === timerange);

    return (
        <Stack direction="row" spacing={1} alignItems="stretch">
            <FeedbackDialog
                open={feedbackDialog.isOpen}
                title={translate(translationKeys.VDLANG_METHODS_SUGGEST_METHOD)}
                type={FeedbackType.SUGGEST_METHOD}
                onClose={feedbackDialog.close}
                translate={translate}
            />
            <SearchInput
                fullWidth={false}
                translate={translate}
                searchKey={searchKey}
                onChange={onSearchKeyChange}
                inputProps={{ sx: { py: 1 } }}
            />
            <Select
                margin="none"
                options={timerangeOptions}
                value={selectedTimerangeOption}
                onChange={(o) => o != null && onTimerangeChange(o.value)}
            />
            <Box flexGrow={1} />
            <Button variant="contained" onClick={feedbackDialog.open}>
                {translate(translationKeys.VDLANG_METHODS_SUGGEST_METHOD)}
            </Button>
            <ToggleButtonGroup size="small" exclusive value={String(view)} onChange={updateView}>
                {Object.entries(MethodViewTypeButtons).map(([key, Icon]) => (
                    <ToggleButton value={key} key={key}>
                        <Icon />
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Stack>
    );
};

export default MethodOverviewBanner;
