import { Card, CardHeader, Container, Grid } from "@mui/material";
import {
    AttributeTitle,
    EffectFilterCurrencyField,
    EffectFilterDateField,
    FeatureFlags,
    FilterDefinition,
    MeasureFieldNames,
    MeasureStatus,
    Operators,
    Sort,
} from "api-shared";
import { TFunction } from "i18next";
import { useMemo, Suspense } from "react";
import { useParams } from "react-router-dom";
import ErrorBanner from "../../components/ErrorBanner";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import MeasuresWidget from "../../components/measures/MeasuresWidget";
import { useClientHasFeature } from "../../domain/client";
import { useMeasureFieldDefinitionsQuery } from "../../domain/filters";
import { useMethodQuery, useMethodsQuery } from "../../domain/methods/methods";
import { translationKeys } from "../../translations/main-translations";
import ActivityTemplate from "./ActivityTemplate";
import MethodDetail from "./MethodDetail";
import MethodFiles from "./MethodFiles";

const MEASURES_WIDGET_COLUMNS = [
    MeasureFieldNames.Title,
    MeasureFieldNames.AssignedToId,
    EffectFilterCurrencyField.Effect,
    EffectFilterDateField.StartDate,
];

interface MethodDetailViewProps {
    updateFavorite: (methodId: number, newStatus: boolean) => void;
    translate: TFunction;
}

const MethodDetailView = ({ updateFavorite, translate }: MethodDetailViewProps) => {
    const { code } = useParams<{ code: string }>();
    const methodsQuery = useMethodsQuery();
    const methodIdFromUrl = (methodsQuery.data ?? []).find((cl) => cl.code === code)?.id;

    const methodQuery = useMethodQuery({ methodId: methodIdFromUrl });
    const hasActivityTemplateFeature = useClientHasFeature(FeatureFlags.FEATURE_ACTIVITY_TEMPLATES);

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const hasCostLeverAttribute = fieldDefinitionsQuery.data?.[AttributeTitle.CostLeverId] != null;

    const measureFilter: FilterDefinition = useMemo(() => {
        if (!hasCostLeverAttribute) {
            // CostLeverId field is not available -> use filter that results in no measures
            return [
                {
                    field: MeasureFieldNames.ClientIid,
                    operator: Operators.SmallerThan,
                    values: [String(0)],
                },
            ];
        }

        return [
            {
                field: AttributeTitle.CostLeverId,
                operator: Operators.In,
                values: [String(methodIdFromUrl)],
            },
            {
                field: MeasureFieldNames.Status,
                operator: Operators.NotIn,
                values: [String(MeasureStatus.STATUS_DISCARDED)],
            },
        ];
    }, [methodIdFromUrl, hasCostLeverAttribute]);

    if (methodsQuery.isError || methodQuery.isError || (methodsQuery.isSuccess && methodIdFromUrl == null)) {
        return <ErrorBanner>{translate(translationKeys.VDLANG_METHODS_METHOD_NOT_FOUND)}</ErrorBanner>;
    }

    if (!methodQuery.isSuccess || !methodsQuery.isSuccess || !fieldDefinitionsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const method = methodQuery.data;

    return (
        <Container maxWidth="lg" disableGutters sx={{ pb: 2 }}>
            <Suspense fallback={<LoadingAnimation />}>
                <Grid container spacing={1} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <MethodDetail
                            method={method}
                            updateFavorite={(newStatus: boolean) => updateFavorite(method.id, newStatus)}
                            hasCostLeverAttribute={hasCostLeverAttribute}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={translate(translationKeys.VDLANG_METHOD_MEASURE_WIDGET_TITLE)} />
                            <MeasuresWidget
                                columns={MEASURES_WIDGET_COLUMNS}
                                orderBy={EffectFilterCurrencyField.Effect}
                                sort={Sort.DESCENDING}
                                filter={measureFilter}
                                dataKey="byMethod"
                            />
                        </Card>
                    </Grid>
                    {hasActivityTemplateFeature ? (
                        <Grid item xs={12} md={4}>
                            <ActivityTemplate methodId={method.id} />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} md={hasActivityTemplateFeature ? 8 : 12}>
                        <MethodFiles methodId={method.id} />
                    </Grid>
                </Grid>
            </Suspense>
        </Container>
    );
};

export default MethodDetailView;
