import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNextRounded";
import { Button, Stack, styled } from "@mui/material";
import { MethodDetailDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DefaultButton from "../../components/DefaultButton";
import Tooltip from "../../components/Tooltip";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";

interface MethodDetailBannerProps {
    previousMethod: MethodDetailDto | undefined;
    nextMethod: MethodDetailDto | undefined;
}

const NavButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    padding: theme.spacing(0.625),
    borderColor: theme.palette.divider,
    color: theme.palette.action.active,
})) as typeof Button; // https://github.com/mui/material-ui/issues/15759#issuecomment-984553630

const MethodDetailBanner = ({ previousMethod, nextMethod }: MethodDetailBannerProps) => {
    const { t: translate } = useTranslation();

    return (
        <Stack direction="row" alignItems="stretch" justifyContent="space-between" spacing={2}>
            <DefaultButton component={Link} to={RouteFor.method.view} color="inherit" startIcon={<ChevronLeftIcon />}>
                {translate(translationKeys.VDLANG_METHODS_NAVBAR_ITEM_LABEL)}
            </DefaultButton>
            <Stack direction="row" spacing={1}>
                {previousMethod != null ? (
                    <Tooltip title={previousMethod.code}>
                        <NavButton color="inherit" component={Link} to={RouteFor.method.forCode(previousMethod.code)}>
                            <NavigateBeforeIcon />
                        </NavButton>
                    </Tooltip>
                ) : null}
                {nextMethod != null ? (
                    <Tooltip title={nextMethod.code}>
                        <NavButton color="inherit" component={Link} to={RouteFor.method.forCode(nextMethod.code)}>
                            <NavigateNextIcon />
                        </NavButton>
                    </Tooltip>
                ) : null}
            </Stack>
        </Stack>
    );
};

export default MethodDetailBanner;
