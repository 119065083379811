import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, MethodFileDto, MethodFileListDto } from "api-shared";
import { useProgress } from "../../hooks/useProgress";
import { apiDelete, apiGet, apiUpload } from "../../lib/api";
import { MethodQueryKeys } from "./query-keys";

type UseMethodFilesOptions = {
    methodId: number;
};

export const useMethodFiles = ({ methodId }: UseMethodFilesOptions) => {
    return useQuery({
        queryKey: MethodQueryKeys.listFilesForMethod(methodId),
        queryFn: ({ queryKey, signal }) => apiGet<MethodFileListDto>(`methods/${queryKey[1]}/files`, { signal }),
    });
};

type UseUploadMethodFileOptions = {
    methodId: number;
    file: File;
};

export const useUploadMethodFile = () => {
    const queryClient = useQueryClient();
    const [progress, setProgress] = useProgress();

    const mutation = useMutation({
        mutationFn: ({ methodId, file }: UseUploadMethodFileOptions) => {
            const data = new FormData();
            data.append("file", file);

            return apiUpload<MethodFileDto>(`methods/${methodId}/files`, data, {
                onProgress: (event) => setProgress(event),
            });
        },
        onSuccess: (_, { methodId }) => {
            queryClient.invalidateQueries(MethodQueryKeys.listFilesForMethod(methodId));
        },
        onSettled: () => {
            setProgress(0);
        },
    });

    return { ...mutation, progress };
};

type UseDeleteMethodFileOptions = {
    methodId: number;
    fileId: number;
};

export const useDeleteMethodFile = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ methodId, fileId }: UseDeleteMethodFileOptions) => apiDelete<BasicMessageDto>(`methods/${methodId}/files/${fileId}`),
        onSuccess: (_, { methodId }) => {
            queryClient.invalidateQueries(MethodQueryKeys.listFilesForMethod(methodId));
        },
    });
};
