import { styled } from "@mui/material";
import RelevancySquare from "./RelevancySquare";

const CellContainer = styled("div")(() => ({
    textAlign: "center",
}));

export interface ITableRelevancyCellProps {
    value?: number;
}

function TableRelevancyCell({ value }: ITableRelevancyCellProps) {
    return typeof value === "number" && value >= 0 && value <= 5 ? (
        <CellContainer>
            {Array(5)
                .fill(undefined)
                .map((_, index) => (
                    <RelevancySquare key={index} disabled={index >= value} />
                ))}
        </CellContainer>
    ) : null;
}

export default TableRelevancyCell;
