import { Box, Card, CardContent, CardHeader, cardHeaderClasses, Grid, ListItemText, styled, Typography } from "@mui/material";
import { MethodDetailDto } from "api-shared";
import { TFunction } from "i18next";
import Dotdotdot from "react-dotdotdot";
import FavoriteIcon from "../../components/icons/FavoriteIcon";
import { UnstyledLink } from "../../components/Link";
import { CurrencyFormatter } from "../../hooks/useCurrency";
import { RouteFor } from "../../lib/routes";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";

const RootCard = styled(Card)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    [`& .${cardHeaderClasses.root}`]: {
        alignItems: "flex-start",
    },
    [`& .${cardHeaderClasses.content}`]: {
        minHeight: "4em", // magic number to be in sync with 2-line clamp of title
    },
}));

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
    padding: theme.spacing(0.5),
    // negative margin to so that icon is positioned as if it had no IconButton padding
    marginTop: theme.spacing(-0.5),
    // also apply bottom negative margin, so that icon does not exceed line-height, which would confuse react-dotdotdot
    marginBottom: theme.spacing(-0.5),
}));

const ValuesGrid = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    columnGap: theme.spacing(2),
}));

const MethodHeader = styled(CardHeader)({
    overflowWrap: "anywhere",
});

interface MethodTileProps {
    method: MethodDetailDto;
    language: Language;
    translate: TFunction;
    updateFavorite: (methodId: number, newStatus: boolean) => void;
    formatCurrency: CurrencyFormatter;
}

const MethodTile = ({ formatCurrency, method, language, translate, updateFavorite }: MethodTileProps) => {
    return (
        <UnstyledLink to={RouteFor.method.forCode(method.code)}>
            <RootCard>
                <MethodHeader
                    titleTypographyProps={{
                        variant: "h6",
                    }}
                    title={
                        <Dotdotdot clamp={2}>
                            <StyledFavoriteIcon
                                value={method.isFavorite ?? false}
                                updateValue={
                                    typeof updateFavorite === "function"
                                        ? () => updateFavorite(method.id, !method.isFavorite)
                                        : updateFavorite
                                }
                                translate={translate}
                            />
                            {`${method.code} - ${translateFromProperty(method, "name", language)}`}
                        </Dotdotdot>
                    }
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography component="div">
                        <Dotdotdot clamp={5}>{translateFromProperty(method, "hint", language)}</Dotdotdot>
                    </Typography>
                </CardContent>
                <Box px={2} pb={2}>
                    <ValuesGrid container>
                        <Grid item xs={12} lg={true}>
                            <ListItemText
                                primary={formatCurrency(method.totalEffect ?? 0)}
                                secondary={translate(translationKeys.VDLANG_METHOD_TILE_EFFECT_LABEL)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={true}>
                            <ListItemText primary={method.totalNumberOfMeasures} secondary={translate("processes")} />
                        </Grid>
                    </ValuesGrid>
                </Box>
            </RootCard>
        </UnstyledLink>
    );
};

export default MethodTile;
