import { Button, Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { AclNamespaces, AclPermissions, AttributeTitle, MethodDetailDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Currency from "../../components/Currency";
import FavoriteIcon from "../../components/icons/FavoriteIcon";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useCreateMeasure } from "../../domain/measure/detail";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import { translateFromProperty } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import CallToActionDialog from "../navbar/CallToActionDialog";

interface MethodDetailProps {
    method: MethodDetailDto;
    updateFavorite: (newStatus: boolean) => void;
    hasCostLeverAttribute: boolean;
}

const MethodDetail = ({ method, updateFavorite, hasCostLeverAttribute }: MethodDetailProps) => {
    const measureConfigs = useMeasureConfigs();
    const navigate = useNavigate();
    const createMeasureMutation = useCreateMeasure(({ id }) => navigate(`/measure/${id}`));
    const { t: translate } = useTranslation();
    const language = useLanguage();

    const createMeasureDialog = useDialog();

    const { numberOfEffectiveMeasures, effectiveEffect = 0 } = method;
    const averageEffectiveEffect = numberOfEffectiveMeasures ? effectiveEffect / numberOfEffectiveMeasures : 0;

    const name = translateFromProperty(method, "name", language);
    const hint = translateFromProperty(method, "hint", language);

    const title = `${method.code} - ${name}`;

    const measureConfigsWithoutCostLeverAttribute = measureConfigs
        .filter((mc) => !mc.measureAttributes.map((ma) => ma.title).includes(AttributeTitle.CostLeverId))
        .map((mc) => mc.id);

    const canCreateProcessQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Create,
        fact: {},
    });

    return (
        <Card>
            <CallToActionDialog
                show={createMeasureDialog.isOpen}
                onHide={createMeasureDialog.close}
                translate={translate}
                createMeasure={createMeasureMutation.mutate}
                // Only pass method to the dialog, if there actually is a process configured with the method attribute
                method={hasCostLeverAttribute ? method : undefined}
                methodTitle={hasCostLeverAttribute ? title : undefined}
                measureConfigs={measureConfigs}
                disabledMeasureConfigIds={measureConfigsWithoutCostLeverAttribute}
                disabledMeasureConfigHint={translate(translationKeys.VDLANG_METHODS_DETAIL_NO_COST_LEVER_ATTRIBUTE)}
                userCanCreateProcess={canCreateProcessQuery.data?.hasPermission ?? false}
                userHasIdeaAccess={false}
            />
            <CardHeader
                title={
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <FavoriteIcon value={method.isFavorite ?? false} updateValue={updateFavorite} translate={translate} />
                        <span>{title}</span>
                    </Stack>
                }
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography paragraph>{hint}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography align="center" variant="h4">
                                {method.totalNumberOfMeasures}
                            </Typography>
                            <Typography align="center">{translate(translationKeys.VDLANG_METHODS_DETAIL_PROCESSES)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h4">
                                {method.numberOfEffectiveMeasures}
                            </Typography>
                            <Typography align="center">{translate(translationKeys.VDLANG_METHODS_DETAIL_EFFECTIVE_MEASURES)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h4">
                                <Currency value={averageEffectiveEffect} />
                            </Typography>
                            <Typography align="center">
                                {translate(translationKeys.VDLANG_METHODS_DETAIL_AVERAGE_EFFECTIVE_EFFECT)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                {measureConfigs.length > measureConfigsWithoutCostLeverAttribute.length && (
                    <Button color="primary" variant="contained" onClick={createMeasureDialog.open}>
                        {translate(translationKeys.VDLANG_METHODS_DETAIL_START_A_PROCESS)}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default MethodDetail;
